/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import LoginPage from 'modules/login';
import DashboardPage from 'modules/dashboard';
import UsersPage from 'modules/users';
import UserDetailsPage from 'modules/user-details';
import UserScopesPage from 'modules/userScopes';
import UserAccountsPage from 'modules/accounts';
import UserInvoicesPage from 'modules/invoices';
import UserSubscriptionsPage from 'modules/subscriptions';
import CardsPage from 'modules/cards';
import PromoCodesPage from 'modules/promoCodes';
import TransactionsAllPage from 'modules/transactionsAll';
import TransactionsApprovePage from 'modules/transactionsApprover';
import CompaniesPage from 'modules/companies';
import LoansPage from 'modules/loans';
import ComponentsPage from 'modules/components';
import LoanDetailsPage from 'modules/loan-details';

import {
  LayoutBody,
  LayoutMain,
  LayoutMenu,
  LayoutNavbar,
  LayoutPage,
  LayoutSidebar,
  LayoutModal,
} from './layout';
import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import { ProvideBaseStores, useBaseStores } from 'providers/BaseStoresProvider';
import { Navigate, Route, Routes } from 'react-router-dom';

const AppWithBaseProviders = observer(() => {
  const { authStore } = useBaseStores();

  if (!authStore.isLoggedIn) {
    return <LoginPage />;
  }

  return (
    <LayoutMain>
      <LayoutMenu />
      <LayoutBody>
        <LayoutNavbar />
        <LayoutPage>
          <Routes>
            <Route path={ROUTES.index.path} element={<DashboardPage />} />
            <Route path={ROUTES.users.path} element={<UsersPage />} />
            <Route
              path={ROUTES.userDetails.path}
              element={<UserDetailsPage />}
            />
            <Route path={ROUTES.userScopes.path} element={<UserScopesPage />} />
            <Route path={ROUTES.components.path} element={<ComponentsPage />} />
            <Route path={ROUTES.accounts.path} element={<UserAccountsPage />} />
            <Route path={ROUTES.invoices.path} element={<UserInvoicesPage />} />
            <Route
              path={ROUTES.subscriptions.path}
              element={<UserSubscriptionsPage />}
            />
            <Route path={ROUTES.cards.path} element={<CardsPage />} />
            <Route path={ROUTES.promoCodes.path} element={<PromoCodesPage />} />
            <Route path={ROUTES.company.path} element={<CompaniesPage />} />
            <Route
              path={ROUTES.transactions.path}
              element={<TransactionsAllPage />}
            />
            <Route
              path={ROUTES.transactionsApprove.path}
              element={<TransactionsApprovePage />}
            />
            <Route path={ROUTES.loans.path} element={<LoansPage />} />
            <Route
              path={ROUTES.loanDetails.path}
              element={<LoanDetailsPage />}
            />
            <Route
              path='*'
              element={<Navigate to={ROUTES.index.fullPath} replace />}
            />
          </Routes>
        </LayoutPage>
      </LayoutBody>

      <LayoutSidebar />
      <LayoutModal />
    </LayoutMain>
  );
});

const App = () => {
  return (
    <ProvideBaseStores>
      <AppWithBaseProviders />
    </ProvideBaseStores>
  );
};

export default App;
