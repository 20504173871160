/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  AdminUser,
  UserAddress,
  UserEmailAddressDto,
  UserStatusDto,
  UserType,
  VerificationStatus,
} from 'api/auth';

export { VerificationStatus as UserVerificationStatus } from 'api/auth';

export class UserModel implements AdminUser {
  constructor(dto: AdminUser) {
    this.id = dto.id;
    this.name =
      (dto.firstName ? dto.firstName : '') +
      ' ' +
      (dto.lastName ? dto.lastName : '');
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.emails = dto.emails;
    this.approved = dto.status === UserStatusDto.ACTIVE;
    this.phone = dto.phone;
    this.enabled = dto.status === UserStatusDto.ACTIVE;
    this.verificationStatus = dto.verificationStatus!;
    this.addresses = dto.addresses;
    this.residentialAddress = dto.residentialAddress;
    this.status = dto.status;
    this.partner = dto.partner;
    this.userType = dto.userType;
    this.companyInfoId = dto.companyInfoId;
    this.externalPartnerId = dto.externalPartnerId;
  }

  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  status: UserStatusDto;
  enabled: boolean;
  approved: boolean;
  companyInfoId?: string;
  email?: string;
  emails?: UserEmailAddressDto[];
  phone?: string;
  residentialAddress?: UserAddress;
  verificationStatus: VerificationStatus;
  addresses?: UserAddress[];
  partner: string;
  externalPartnerId?: string;
  userType: UserType;
}
