/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AdminSubscriptionDto, RecurrentInvoiceStatus } from 'api/auth';
import { parseBigNumberZero } from 'utils/bignumber';
import BigNumber from 'bignumber.js';

export class SubscriptionsUserModel {
  constructor(dto: AdminSubscriptionDto) {
    this.id = dto.id ?? '';
    this.amount = parseBigNumberZero(dto.amount);
    this.initialPaymentAmount = parseBigNumberZero(dto.initialPaymentAmount);
    this.currency = dto.currency;
    this.deleted = dto.deleted;
    this.status = dto.status;
  }

  id: string;
  amount: BigNumber;
  currency: string;
  deleted: boolean;
  initialPaymentAmount: BigNumber;
  status: RecurrentInvoiceStatus;
}
