/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const ROUTES = {
  index: {
    path: '/',
    fullPath: '/',
  },
  users: {
    path: '/users/*',
    fullPath: '/users',
    users: {
      path: '/users',
    },
    groups: {
      path: '/groups',
      fullPath: '/users/groups',
    },
  },
  userDetails: {
    path: '/user/:userId/*',
    getFullPath: (userId: string) => `/user/${userId}`,
    notes: {
      path: '/',
      getFullPath: (userId: string) => `/user/${userId}`,
    },
    sessions: {
      path: '/sessions',
      getFullPath: (userId: string) => `/user/${userId}/sessions`,
    },
    kyc: {
      path: '/kyc',
      getFullPath: (userId: string) => `/user/${userId}/kyc`,
    },
    kyb: {
      path: '/kyb',
      getFullPath: (userId: string) => `/user/${userId}/kyb`,
    },
    loans: {
      path: '/loans',
      getFullPath: (userId: string) => `/user/${userId}/loans`,
    },
    accounts: {
      path: '/accounts',
      getFullPath: (userId: string) => `/user/${userId}/accounts`,
    },
    accountDetails: {
      path: '/accounts/:accountId/*',
      getFullPath: (userId: string, accountId: string) =>
        `/user/${userId}/accounts/${accountId}`,
      balances: {
        path: '/balances',
        getFullPath: (userId: string, accountId: string) =>
          `/user/${userId}/accounts/${accountId}/balances`,
      },
      history: {
        path: '/history',
        getFullPath: (userId: string, accountId: string) =>
          `/user/${userId}/accounts/${accountId}/history`,
      },
    },
    withdrawals: {
      path: '/withdrawals',
      getFullPath: (userId: string) => `/user/${userId}/withdrawals`,
    },
    deposits: {
      path: '/deposits',
      getFullPath: (userId: string) => `/user/${userId}/deposits`,
    },
    cards: {
      path: '/cards',
      getFullPath: (userId: string) => `/user/${userId}/cards`,
    },
    invoices: {
      path: '/invoices',
      getFullPath: (userId: string) => `/user/${userId}/invoices`,
    },
    subscriptions: {
      path: '/subscriptions',
      getFullPath: (userId: string) => `/user/${userId}/subscriptions`,
    },
    transactions: {
      path: '/transactions',
      getFullPath: (userId: string) => `/user/${userId}/transactions`,
    },
  },
  loans: {
    path: '/loans/*',
    fullPath: '/loans',
    offers: {
      path: '/offers',
      fullPath: '/loans/offers',
    },
    list: {
      path: '/list',
      fullPath: '/loans/list',
    },
    margin_calls: {
      path: '/margin_calls',
      fullPath: '/loans/margin_calls',
    },
    liquidations: {
      path: '/liquidations',
      fullPath: '/loans/liquidations',
    },
    payments: {
      path: '/payments',
      fullPath: '/loans/payments',
    },
    compliance: {
      path: '/compliance',
      fullPath: '/loans/compliance',
    },
  },
  loanDetails: {
    path: '/loan/:loanId/*',
    getFullPath: (loanId: string) => `/loan/${loanId}`,
    management: {
      path: '/',
      getFullPath: (loanId: string) => `/loan/${loanId}`,
    },
  },
  userScopes: {
    path: '/user-scopes',
    fullPath: '/user-scopes',
  },
  accounts: {
    path: '/accounts',
    fullPath: '/accounts',
  },
  invoices: {
    path: '/invoices',
    fullPath: '/invoices',
  },
  subscriptions: {
    path: '/subscriptions',
    fullPath: '/subscriptions',
  },
  cards: {
    path: '/cards',
    fullPath: '/cards',
  },
  promoCodes: {
    path: '/promo-codes',
    fullPath: '/promo-codes',
  },
  transactions: {
    path: '/transactions',
    fullPath: '/transactions',
  },
  transactionsApprove: {
    path: '/transactions-approve',
    fullPath: '/transactions-approve',
  },
  company: {
    path: '/company-info',
    fullPath: '/company-info',
  },
  groups: {
    path: '/groups',
    fullPath: '/groups',
  },
  components: {
    path: '/components',
    fullPath: '/components',
  },
} as const;
